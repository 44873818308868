import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const HEAT_PUMP_3050_LOCALES = {
	WOLTAIR_CZ: 'tepelna-cerpadla-50-50',
	WOLTAIR_PL: 'pompy-ciepla-30-50',
	WOLTAIR_DE: 'waermepumpen-30-50',
	WOLTAIR_IT: 'pompe-di-calore-30-50'
}

export const HEAT_PUMP_3050 = HEAT_PUMP_3050_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return HEAT_PUMP_3050 === param
}) satisfies ParamMatcher
